import "./Tagline.css";

function Tagline({ visibility }) {
  return (
    <h2 className={"tagline " + (visibility ? "" : " hidden")}>
      <span>
        Webdoku zu On- und Offline-Dynamiken antidemokratischer Bewegungen
      </span>
    </h2>
  );
}

export default Tagline;
