import "./Logo.css";

function Logo({ animate, visibility, toggleLogo }) {
  return (
    <h1
      className={"logo glitch " + (animate ? "animate" : "")}
      data-glitch={visibility ? "Interferenzen" : "I"}
      onClick={toggleLogo}
    >
      I<span className={visibility ? "" : " hidden"}>nterferenzen</span>
    </h1>
  );
}

export default Logo;
