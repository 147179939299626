import MetaTags from "../MetaTags";

export default function PageEpisodes() {
  return (
    <>
      <MetaTags title={"Impressum | Interferenzen"} />
      <div className="modal-container static-page-content">
        <div className="content">
          <h2>Impressum</h2>
          <p>Angaben zur Anbieterkennzeichnungspflicht:</p>
          <p>
            <b>Kontakt</b>
          </p>
          <p>democ e. V., Mahlower Str. 24, 12049 Berlin</p>
          <p>E-Mail: kontakt@democ.de</p>
          <p>
            <b>Gesetzlich vertreten durch den Vorstand</b>
          </p>
          <p>Matthias Schröder / democ e. V., Mahlower Str. 24, 12049 Berlin</p>
          <p>
            <b>Vereinsregister</b>
          </p>
          <p>
            democ e. V. ist im Vereinsregister beim Amtsgericht
            Berlin-Charlottenburg unter der Registernummer VR 37944 B
            eingetragen.
          </p>
          <p>
            <b>Steuerliche Angaben</b>
          </p>
          <p>Steuernummer: 27/663/65292</p>
          <p>
            <b>
              Redaktionell verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
            </b>
          </p>
          <p>Matthias Schröder / democ e. V., Mahlower Str. 24, 12049 Berlin</p>
          <p>
            <b>Haftungsausschluss:</b>
          </p>
          <p>
            <b>Haftung für Inhalte</b>
          </p>
          <p>
            Als Diensteanbieter sind wir für eigene Inhalte auf diesen Seiten
            nach den allgemeinen Gesetzen verantwortlich. Wir sind als
            Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>
          <p>
            <b>Haftung für Links</b>
          </p>
          <p>
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
          <p>
            <b>Urheberrecht</b>
          </p>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
          </p>
          <h1 style={{ marginTop: "8rem" }}>Datenschutzerklärung</h1>
          <h2>Einleitung</h2>
          <p>
            Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
            aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
            auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in
            welchem Umfang im Rahmen der Bereitstellung unserer Applikation
            verarbeiten. Die verwendeten Begriffe sind nicht
            geschlechtsspezifisch.
          </p>
          <p>Stand: 22. Oktober 2022</p>
          <h2>Inhaltsübersicht</h2>{" "}
          <ul className="index">
            <li>
              <a className="index-link" href="#m1870">
                Einleitung
              </a>
            </li>
            <li>
              <a className="index-link" href="#m3">
                Verantwortlicher
              </a>
            </li>
            <li>
              <a className="index-link" href="#mOverview">
                Übersicht der Verarbeitungen
              </a>
            </li>
            <li>
              <a className="index-link" href="#m13">
                Maßgebliche Rechtsgrundlagen
              </a>
            </li>
            <li>
              <a className="index-link" href="#m27">
                Sicherheitsmaßnahmen
              </a>
            </li>
            <li>
              <a className="index-link" href="#m225">
                Bereitstellung des Onlineangebotes und Webhosting
              </a>
            </li>
            <li>
              <a className="index-link" href="#m263">
                Webanalyse, Monitoring und Optimierung
              </a>
            </li>
            <li>
              <a className="index-link" href="#m15">
                Änderung und Aktualisierung der Datenschutzerklärung
              </a>
            </li>
            <li>
              <a className="index-link" href="#m10">
                Rechte der betroffenen Personen
              </a>
            </li>
            <li>
              <a className="index-link" href="#m42">
                Begriffsdefinitionen
              </a>
            </li>
          </ul>
          <h2 id="m3">Verantwortlicher</h2>
          <p>
            Matthias Schröder
            <br />
            democ e. V.,
            <br />
            Mahlower Str. 24,
            <br />
            12049 Berlin
          </p>
          <p>
            Vertretungsberechtigte Personen:
            <br />
            Matthias Schröder (democ e. V.)
          </p>
          <p>
            E-Mail-Adresse:{" "}
            <a href="mailto:kontakt@democ.de">kontakt@democ.de</a>
          </p>
          <p>
            Impressum:{" "}
            <a href="/impressum/">https://interferenzen.democ.de/impressum/</a>
          </p>
          <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
          <p>
            Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten
            und die Zwecke ihrer Verarbeitung zusammen und verweist auf die
            betroffenen Personen.
          </p>
          <h3>Arten der verarbeiteten Daten</h3>
          <ul>
            <li>Nutzungsdaten.</li>
            <li>Meta-/Kommunikationsdaten.</li>
          </ul>
          <h3>Kategorien betroffener Personen</h3>
          <ul>
            <li>Nutzer.</li>
          </ul>
          <h3>Zwecke der Verarbeitung</h3>
          <ul>
            <li>Sicherheitsmaßnahmen.</li>
            <li>Reichweitenmessung.</li>
            <li>Profile mit nutzerbezogenen Informationen.</li>
            <li>
              Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
            </li>
            <li>Informationstechnische Infrastruktur.</li>
          </ul>
          <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
          <p>
            Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der
            DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte
            nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO
            nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder
            Sitzland gelten können. Sollten ferner im Einzelfall speziellere
            Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der
            Datenschutzerklärung mit.
          </p>
          <ul>
            <li>
              <strong>
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
              </strong>{" "}
              - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
              Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
              Interessen oder Grundrechte und Grundfreiheiten der betroffenen
              Person, die den Schutz personenbezogener Daten erfordern,
              überwiegen.
            </li>
          </ul>
          <p>
            Zusätzlich zu den Datenschutzregelungen der
            Datenschutz-Grundverordnung gelten nationale Regelungen zum
            Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz
            zum Schutz vor Missbrauch personenbezogener Daten bei der
            Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
            insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
            Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
            Kategorien personenbezogener Daten, zur Verarbeitung für andere
            Zwecke und zur Übermittlung sowie automatisierten
            Entscheidungsfindung im Einzelfall einschließlich Profiling. Des
            Weiteren regelt es die Datenverarbeitung für Zwecke des
            Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick
            auf die Begründung, Durchführung oder Beendigung von
            Beschäftigungsverhältnissen sowie die Einwilligung von
            Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen
            Bundesländer zur Anwendung gelangen.
          </p>
          <h2 id="m27">Sicherheitsmaßnahmen</h2>
          <p>
            Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
            Berücksichtigung des Stands der Technik, der Implementierungskosten
            und der Art, des Umfangs, der Umstände und der Zwecke der
            Verarbeitung sowie der unterschiedlichen
            Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der
            Rechte und Freiheiten natürlicher Personen geeignete technische und
            organisatorische Maßnahmen, um ein dem Risiko angemessenes
            Schutzniveau zu gewährleisten.
          </p>
          <p>
            Zu den Maßnahmen gehören insbesondere die Sicherung der
            Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
            Kontrolle des physischen und elektronischen Zugangs zu den Daten als
            auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
            Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben
            wir Verfahren eingerichtet, die eine Wahrnehmung von
            Betroffenenrechten, die Löschung von Daten und Reaktionen auf die
            Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den
            Schutz personenbezogener Daten bereits bei der Entwicklung bzw.
            Auswahl von Hardware, Software sowie Verfahren entsprechend dem
            Prinzip des Datenschutzes, durch Technikgestaltung und durch
            datenschutzfreundliche Voreinstellungen.
          </p>
          <p>
            Kürzung der IP-Adresse: Sofern IP-Adressen von uns oder von den
            eingesetzten Dienstleistern und Technologien verarbeitet werden und
            die Verarbeitung einer vollständigen IP-Adresse nicht erforderlich
            ist, wird die IP-Adresse gekürzt (auch als "IP-Masking" bezeichnet).
            Hierbei werden die letzten beiden Ziffern, bzw. der letzte Teil der
            IP-Adresse nach einem Punkt entfernt, bzw. durch Platzhalter
            ersetzt. Mit der Kürzung der IP-Adresse soll die Identifizierung
            einer Person anhand ihrer IP-Adresse verhindert oder wesentlich
            erschwert werden.
          </p>
          <p>
            TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot
            übermittelten Daten zu schützen, nutzen wir eine
            TLS-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen
            an dem Präfix https:// in der Adresszeile Ihres Browsers.
          </p>
          <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
          <p>
            Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste
            zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
            IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und
            Funktionen unserer Online-Dienste an den Browser oder das Endgerät
            der Nutzer zu übermitteln.
          </p>
          <ul className="m-elements">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
              besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z.B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
              Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
              Infrastruktur (Betrieb und Bereitstellung von Informationssystemen
              und technischen Geräten (Computer, Server etc.).);
              Sicherheitsmaßnahmen.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </li>
          </ul>
          <p>
            <strong>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </strong>
          </p>
          <ul className="m-elements">
            <li>
              <strong>
                Bereitstellung Onlineangebot auf gemietetem Speicherplatz:{" "}
              </strong>
              Für die Bereitstellung unseres Onlineangebotes nutzen wir
              Speicherplatz, Rechenkapazität und Software, die wir von einem
              entsprechenden Serveranbieter (auch "Webhoster" genannt) mieten
              oder anderweitig beziehen; <strong>Rechtsgrundlagen:</strong>{" "}
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </li>
            <li>
              <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der
              Zugriff auf unser Onlineangebot wird in Form von so genannten
              "Server-Logfiles" protokolliert. Zu den Serverlogfiles können die
              Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
              Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
              erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem
              des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
              Regelfall IP-Adressen und der anfragende Provider gehören. Die
              Serverlogfiles können zum einen zu Zwecken der Sicherheit
              eingesetzt werden, z.B., um eine Überlastung der Server zu
              vermeiden (insbesondere im Fall von missbräuchlichen Angriffen,
              sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der
              Server und ihre Stabilität sicherzustellen;{" "}
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO); <strong>Löschung von Daten:</strong>{" "}
              Logfile-Informationen werden für die Dauer von maximal 30 Tagen
              gespeichert und danach gelöscht oder anonymisiert. Daten, deren
              weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis
              zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung
              ausgenommen.
            </li>
            <li>
              <strong>Content-Delivery-Network: </strong>Wir setzen ein
              "Content-Delivery-Network" (CDN) ein. Ein CDN ist ein Dienst, mit
              dessen Hilfe Inhalte eines Onlineangebotes, insbesondere große
              Mediendateien, wie Grafiken oder Programm-Skripte, mit Hilfe
              regional verteilter und über das Internet verbundener Server
              schneller und sicherer ausgeliefert werden können;{" "}
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </li>
          </ul>
          <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
          <p>
            Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der
            Auswertung der Besucherströme unseres Onlineangebotes und kann
            Verhalten, Interessen oder demographische Informationen zu den
            Besuchern, wie z.B. das Alter oder das Geschlecht, als pseudonyme
            Werte umfassen. Mit Hilfe der Reichweitenanalyse können wir z.B.
            erkennen, zu welcher Zeit unser Onlineangebot oder dessen Funktionen
            oder Inhalte am häufigsten genutzt werden oder zur Wiederverwendung
            einladen. Ebenso können wir nachvollziehen, welche Bereiche der
            Optimierung bedürfen.{" "}
          </p>
          <p>
            Neben der Webanalyse können wir auch Testverfahren einsetzen, um
            z.B. unterschiedliche Versionen unseres Onlineangebotes oder seiner
            Bestandteile zu testen und optimieren.
          </p>
          <p>
            Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken
            Profile, d.h. zu einem Nutzungsvorgang zusammengefasste Daten
            angelegt und Informationen in einem Browser, bzw. in einem Endgerät
            gespeichert und aus diesem ausgelesen werden. Zu den erhobenen
            Angaben gehören insbesondere besuchte Webseiten und dort genutzte
            Elemente sowie technische Angaben, wie der verwendete Browser, das
            verwendete Computersystem sowie Angaben zu Nutzungszeiten. Sofern
            Nutzer in die Erhebung ihrer Standortdaten uns gegenüber oder
            gegenüber den Anbietern der von uns eingesetzten Dienste
            einverstanden erklärt haben, können auch Standortdaten verarbeitet
            werden.
          </p>
          <p>
            Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
            nutzen wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch
            Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die
            im Rahmen von Webanalyse, A/B-Testings und Optimierung keine
            Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen)
            gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der
            eingesetzten Software kennen nicht die tatsächliche Identität der
            Nutzer, sondern nur den für Zwecke der jeweiligen Verfahren in deren
            Profilen gespeicherten Angaben.
          </p>
          <ul className="m-elements">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
              besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z.B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
              Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Profile
              mit nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
            </li>
            <li>
              <strong>Sicherheitsmaßnahmen:</strong> IP-Masking
              (Pseudonymisierung der IP-Adresse).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </li>
          </ul>
          <p>
            <strong>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </strong>
          </p>
          <ul className="m-elements">
            <li>
              <strong>Plausible (ohne Cookies): </strong>Bei Plausible handelt
              es sich um eine datenschutzfreundliche Webanalysesoftware, die
              ohne Cookies eingesetzt wird und bei der die Erkennnung
              wiederkehrender Nutzer mit Hilfe eines so genannten "digitalen
              Fingerabdrucks" erfolgt, der anonymisiert gespeichert und alle 24
              Stunden geändert wird; Beim "digitalen Fingerabdruck" werden
              Nutzerbewegungen innerhalb unseres Onlineangebotes mit Hilfe von
              pseudonymisierten IP-Adressen in Kombination mit nutzerseitige
              Browsereinstellungen so erfasst, dass Rückschlüsse auf die
              Identität einzelner Nutzer nicht möglich sind. Die im Rahmen der
              Nutzung von Plausible erhobenen Daten der Nutzer werden nur von
              uns verarbeitet und nicht mit Dritten geteilt;
              <br />
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);
              <br />
              <strong>Website:</strong>{" "}
              <a href="https://plausible.io/" target="_blank" rel="noreferrer">
                https://plausible.io/
              </a>
              .
            </li>
          </ul>
          <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
          <p>
            Wir bitten Sie, sich regelmäßig über den Inhalt unserer
            Datenschutzerklärung zu informieren. Wir passen die
            Datenschutzerklärung an, sobald die Änderungen der von uns
            durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
            informieren Sie, sobald durch die Änderungen eine
            Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
            sonstige individuelle Benachrichtigung erforderlich wird.
          </p>
          <p>
            Sofern wir in dieser Datenschutzerklärung Adressen und
            Kontaktinformationen von Unternehmen und Organisationen angeben,
            bitten wir zu beachten, dass die Adressen sich über die Zeit ändern
            können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
          </p>
          <h2 id="m10">Rechte der betroffenen Personen</h2>
          <p>
            Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu,
            die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
          </p>
          <ul>
            <li>
              <strong>
                Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich
                aus Ihrer besonderen Situation ergeben, jederzeit gegen die
                Verarbeitung der Sie betreffenden personenbezogenen Daten, die
                aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
                Widerspruch einzulegen; dies gilt auch für ein auf diese
                Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
                personenbezogenen Daten verarbeitet, um Direktwerbung zu
                betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
                Verarbeitung der Sie betreffenden personenbezogenen Daten zum
                Zwecke derartiger Werbung einzulegen; dies gilt auch für das
                Profiling, soweit es mit solcher Direktwerbung in Verbindung
                steht.
              </strong>
            </li>
            <li>
              <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
              Recht, erteilte Einwilligungen jederzeit zu widerrufen.
            </li>
            <li>
              <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
              Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet
              werden und auf Auskunft über diese Daten sowie auf weitere
              Informationen und Kopie der Daten entsprechend den gesetzlichen
              Vorgaben.
            </li>
            <li>
              <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend
              den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
              betreffenden Daten oder die Berichtigung der Sie betreffenden
              unrichtigen Daten zu verlangen.
            </li>
            <li>
              <strong>
                Recht auf Löschung und Einschränkung der Verarbeitung:
              </strong>{" "}
              Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
              verlangen, dass Sie betreffende Daten unverzüglich gelöscht
              werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben
              eine Einschränkung der Verarbeitung der Daten zu verlangen.
            </li>
            <li>
              <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
              Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben,
              nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten,
              gängigen und maschinenlesbaren Format zu erhalten oder deren
              Übermittlung an einen anderen Verantwortlichen zu fordern.
            </li>
            <li>
              <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben
              unbeschadet eines anderweitigen verwaltungsrechtlichen oder
              gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
              Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
              gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts
              des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die
              Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
              die Vorgaben der DSGVO verstößt.
            </li>
          </ul>
          <h2 id="m42">Begriffsdefinitionen</h2>
          <p>
            In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
            Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der
            Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO
            definiert. Die gesetzlichen Definitionen sind verbindlich. Die
            nachfolgenden Erläuterungen sollen dagegen vor allem dem Verständnis
            dienen. Die Begriffe sind alphabetisch sortiert.
          </p>
          <ul className="glossary">
            <li>
              <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“
              sind alle Informationen, die sich auf eine identifizierte oder
              identifizierbare natürliche Person (im Folgenden "betroffene
              Person“) beziehen; als identifizierbar wird eine natürliche Person
              angesehen, die direkt oder indirekt, insbesondere mittels
              Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer,
              zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu
              einem oder mehreren besonderen Merkmalen identifiziert werden
              kann, die Ausdruck der physischen, physiologischen, genetischen,
              psychischen, wirtschaftlichen, kulturellen oder sozialen Identität
              dieser natürlichen Person sind.{" "}
            </li>
            <li>
              <strong>Profile mit nutzerbezogenen Informationen:</strong> Die
              Verarbeitung von "Profilen mit nutzerbezogenen Informationen",
              bzw. kurz "Profilen" umfasst jede Art der automatisierten
              Verarbeitung personenbezogener Daten, die darin besteht, dass
              diese personenbezogenen Daten verwendet werden, um bestimmte
              persönliche Aspekte, die sich auf eine natürliche Person beziehen
              (je nach Art der Profilbildung können dazu unterschiedliche
              Informationen betreffend die Demographie, Verhalten und
              Interessen, wie z.B. die Interaktion mit Webseiten und deren
              Inhalten, etc.) zu analysieren, zu bewerten oder, um sie
              vorherzusagen (z.B. die Interessen an bestimmten Inhalten oder
              Produkten, das Klickverhalten auf einer Webseite oder den
              Aufenthaltsort). Zu Zwecken des Profilings werden häufig Cookies
              und Web-Beacons eingesetzt.{" "}
            </li>
            <li>
              <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch
              als Web Analytics bezeichnet) dient der Auswertung der
              Besucherströme eines Onlineangebotes und kann das Verhalten oder
              Interessen der Besucher an bestimmten Informationen, wie z.B.
              Inhalten von Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse
              können Webseiteninhaber z.B. erkennen, zu welcher Zeit Besucher
              ihre Webseite besuchen und für welche Inhalte sie sich
              interessieren. Dadurch können sie z.B. die Inhalte der Webseite
              besser an die Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der
              Reichweitenanalyse werden häufig pseudonyme Cookies und
              Web-Beacons eingesetzt, um wiederkehrende Besucher zu erkennen und
              so genauere Analysen zur Nutzung eines Onlineangebotes zu
              erhalten.{" "}
            </li>
            <li>
              <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
              natürliche oder juristische Person, Behörde, Einrichtung oder
              andere Stelle, die allein oder gemeinsam mit anderen über die
              Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
              entscheidet, bezeichnet.{" "}
            </li>
            <li>
              <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder
              ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
              solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten.
              Der Begriff reicht weit und umfasst praktisch jeden Umgang mit
              Daten, sei es das Erheben, das Auswerten, das Speichern, das
              Übermitteln oder das Löschen.{" "}
            </li>
          </ul>
          <p className="seal">
            <a
              href="https://datenschutz-generator.de/"
              title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
              Schwenke
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
