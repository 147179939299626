import "./ModalVideos.css";
import { getVideos } from "../data";
import { Link } from "react-router-dom";

function VideoInfo({ title, date, description, length }) {
  return (
    <div className="episode-info">
      <h3>{title}</h3>
      <span className="details">
        {date}
        {length && <> – {Math.floor(length / 60)} Min.</>}
      </span>
      {description && <p>{description}</p>}
    </div>
  );
}

function VideoImage({ image, title }) {
  return image ? (
    <div className="episode-image">
      {image && (
        <img
          src={"/images/" + image}
          alt={title}
          className="episode-thumbnail"
        />
      )}
    </div>
  ) : (
    <div className="episode-image image-soon"></div>
  );
}

function VideoDetails({ video }) {
  return (
    <>
      <VideoImage image={video.poster} title={video.title} />
      <VideoInfo
        title={video.title}
        date={video.release}
        description={video.description}
        length={video.length}
      />
    </>
  );
}

function VideoItem({ video, hide }) {
  return video.slug ? (
    <Link
      className={"item item-" + video.id}
      title={video.pageTitle ? video.pageTitle : video.title}
      to={"/video/" + video.slug + "/"}
      onClick={hide}
    >
      <VideoDetails video={video} />
    </Link>
  ) : (
    <div className="item not-released">
      <VideoDetails video={video} />
    </div>
  );
}

function VideoList({ type, hide }) {
  let videos = getVideos(type);
  const listItems = videos.map((video) => (
    <VideoItem key={video.id} video={video} hide={hide} />
  ));
  return listItems;
}

export default function ModalVideos({ type, onCloseClick, hide }) {
  if (type === "episodes") {
    return (
      <div className="modal-container modal-videos modal-episodes">
        <div className="content">
          <h2>Episoden</h2>
          <div className="videos-list episodes-list">
            <VideoList type={"episode"} hide={hide} />
          </div>
        </div>
        <button className="btn btn-close" onClick={onCloseClick}>
          Schließen
        </button>
      </div>
    );
  } else if (type === "interviews") {
    return (
      <div className="modal-container modal-videos modal-interviews">
        <div className="content">
          <h2>Interviews</h2>
          <div className="videos-list interviews-list">
            <VideoList type={"interview"} hide={hide} />
          </div>
        </div>
        <button className="btn btn-close" onClick={onCloseClick}>
          Schließen
        </button>
      </div>
    );
  } else {
    return false;
  }
}
