import { useState, useRef, useCallback } from "react";
import { v4 as uuid } from "uuid";

export default function useMessageQueue() {
  const [messages, setMessages] = useState([]);

  const ref = useRef();
  ref.current = messages;

  const addMessage = useCallback((caption, timeout = 10000) => {
    const id = uuid();
    setMessages([
      ...ref.current,
      {
        id,
        caption,
        timeout: setTimeout(() => {
          removeMessage(id);
        }, timeout),
      },
    ]);
  }, []);

  function removeMessage(id) {
    setMessages(ref.current.filter((msg) => msg.id !== id));
  }

  const clearMessages = useCallback(() => {
    setMessages([]);
  }, []);

  return { addMessage, clearMessages, messages };
}
