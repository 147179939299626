import { Helmet, HelmetProvider } from "react-helmet-async";

import "./Error.css";
export default function Error() {
  // ToDo: throw proper 404 status code as response

  return (
    <HelmetProvider>
      <div className="error">
        <Helmet>
          <title>Fehler 404 | Interferenzen</title>
          <meta name="errorpage" content="true" />
          <meta name="errortype" content="404 - Not Found" />
          {/* tell prerender to send 404 status code */}
          <meta name="prerender-status-code" content="404" />
        </Helmet>

        <h1>Fehler</h1>
        <p>Die Seite konnte leider nicht gefunden werden. (Error 404)</p>
      </div>
    </HelmetProvider>
  );
}
