import { Routes, Route } from "react-router-dom";
import App from "./App";
import Error from "./Error";

const Routing = () => {
  return (
    <Routes>
      <Route index element={<App />} />
      <Route path="/info" element={<App page={"info"} />} />
      <Route path="/episoden" element={<App page={"episodes"} />} />
      <Route path="/interviews" element={<App page={"interviews"} />} />
      <Route path="/impressum" element={<App page={"imprint"} />} />
      <Route path="/video/:videoSlug" element={<App />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default Routing;
