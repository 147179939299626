import React from "react";
import { Helmet } from "react-helmet-async";

function MetaTags({ title, description, type, image }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta itemprop="name" content={title} />
      <meta itemprop="description" content={description} />
      <meta itemprop="image" content={image} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={window.location} />
      <meta property="og:type" content={type} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content="Interferenzen" />
      <meta name="twitter:creator" content="@democ_de" />
      <meta name="twitter:site" content="@democ_de" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
}

export default MetaTags;
