import "./MessageQueue.css";
import { useState } from "react";

export { default as useMessageQueue } from "./provider";

const MessageItem = ({ message, removeMessage }) => {
  const [isVisible, setIsVisible] = useState(false);

  let text = message.caption;
  let parsedText = text ? text.split(/[\r\n]+/) : false;
  let i = 1;
  let lines = text
    ? parsedText.map((line) => (
        <span key={i++} className={"comment-line line-" + i}>
          {line}
        </span>
      ))
    : false;
  setTimeout(() => {
    setIsVisible(true);
  }, 100);

  return (
    <div
      className={
        "video-overlay video-message" + (isVisible ? " show" : " hidden")
      }
    >
      {lines}
    </div>
  );
};

const MessageQueue = ({ messages, removeMessage }) => {
  return (
    <div className="message-queue">
      {messages.map((msg) => (
        <MessageItem key={msg.id} message={msg} />
      ))}
    </div>
  );
};

export default MessageQueue;
