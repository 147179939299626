import "./VideoLink.css";

function VideoLink({ text, visibility, pause }) {
  let parsedText = text ? text.split(/[\r\n]+/) : false;
  return (
    <div
      className={
        "video-overlay video-overlay-link" + (visibility ? " show" : " hidden")
      }
    >
      <a
        href={parsedText[1]}
        target="_blank"
        rel="noreferrer"
        title={parsedText[0]}
        onClick={pause}
      >
        <svg
          className="info-icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 18"
        >
          <path
            d="M0,2v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V2c0-1.1-0.9-2-2-2H2C0.9,0,0,0.9,0,2z M6.3,4.7l1.4-1.4L13.4,9l-5.7,5.7
      l-1.4-1.4L10.6,9L6.3,4.7z"
          />
        </svg>
        <span className="caption">{parsedText[0]}</span>
      </a>
    </div>
  );
}

export default VideoLink;
