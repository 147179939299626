import "./Page.css";
import PageInfo from "./PageInfo";
import PageEpisodes from "./PageEpisodes";
import PageInterviews from "./PageInterviews";
import PageImprint from "./PageImprint";
import { useEffect } from "react";

export default function Pages({ page, animate }) {
  useEffect(() => {
    animate();
  }, [animate]);

  return (
    <div className="page-content">
      {page === "info" && <PageInfo />}
      {page === "episodes" && <PageEpisodes />}
      {page === "interviews" && <PageInterviews />}
      {page === "imprint" && <PageImprint />}
    </div>
  );
}
