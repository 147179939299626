import "./App.css";
import ModalIntro from "./Modals/ModalIntro";
import ModalInfo from "./Modals/ModalInfo";
import ModalVideos from "./Modals/ModalVideos";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import Logo from "./Logo/Logo";
import Tagline from "./Tagline/Tagline";
import MenuItems from "./MenuItems/MenuItems";
import MetaTags from "./MetaTags";
import Pages from "./Pages/Page";

import { HelmetProvider } from "react-helmet-async";
import { useState, useRef, useCallback, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { getVideo } from "./data";

export default function App({ page = undefined }) {
  const location = useLocation();

  const playerRef = useRef(null);
  let params = useParams();
  let video = useMemo(
    () =>
      params.videoSlug && getVideo(params.videoSlug)
        ? getVideo(params.videoSlug)
        : getVideo("intro"),
    [params.videoSlug]
  );

  // ToDo: Redirect to 404 if video does not exist

  let isIntro = useMemo(
    () => (video.isIntro ? video.isIntro : false),
    [video.isIntro]
  );

  const videoJsOptions = useMemo(() => {
    let tracks = video ? video.tracks : false;
    let loopVideo = video.loop ? video.loop : false;
    let isMuted = isIntro ? true : false;

    return {
      loop: loopVideo,
      autoplay: false, // trigger autoplay programatically instead to support firefox
      // normalizeAutoplay: true,
      muted: isMuted,
      responsive: false,
      controls: false,
      controlBar: false,
      preload: "auto",
      sources: video.sources,
      sourcesMobile: video.sourcesMobile ? video.sourcesMobile : false,
      tracks: tracks,
      disablePictureInPicture: true,
      // textTrackSettings: false,
      poster: "/images/" + video.poster,
      html5: {
        nativeTextTracks: false,
        nativeControlsForTouch: false,
      },
    };
  }, [isIntro, video]);

  const [showInfo, setShowInfo] = useState(false);
  const [showEpisodes, setShowEpisodes] = useState(false);
  const [showInterviews, setShowInterviews] = useState(false);
  const [animateLogo, setAnimateLogo] = useState(false);
  const [logoIsVisible, setLogoIsVisible] = useState(true);

  const changeLocationSoft = (forward, location, destination) => {
    //ToDo: window.history.pushState shouldn't be used with react router
    if (!forward) {
      window.history.pushState({}, undefined, "/" + destination + "/");
    } else {
      window.history.pushState({}, undefined, location.pathname);
    }
  };

  const toggleShowEpisodes = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setShowEpisodes(!showEpisodes);
    setShowInterviews(false);
    triggerLogoAnimation();

    changeLocationSoft(showEpisodes, location, "episoden");
  };

  const toggleShowInterviews = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setShowInterviews(!showInterviews);
    setShowEpisodes(false);
    triggerLogoAnimation();

    changeLocationSoft(showInterviews, location, "interviews");
  };

  const triggerLogoAnimation = useCallback(() => {
    setAnimateLogo(true);
    setTimeout(() => {
      setAnimateLogo(false);
    }, 3000);
  }, []);

  const toggleLogo = useCallback(
    (action) => {
      if (action === "show") {
        // if (!showInfo) // causes re-renders if showInfo is added to dependencies
        setLogoIsVisible(true);
        // 100ms delay before triggering logo animation
        setTimeout(() => {
          triggerLogoAnimation();
        }, 100);
      } else if (action === "hide") {
        setLogoIsVisible(false);
      } else {
        setLogoIsVisible((oldVal) => !oldVal);
      }
    },
    [triggerLogoAnimation]
  );

  const toggleShowInfo = useCallback(() => {
    let action = showInfo ? "hide" : "show";
    // TODO: hide logo only if it was hidden before (because video is playing)
    toggleLogo(action);
    setShowInfo((oldVal) => !oldVal);
    triggerLogoAnimation();
  }, [showInfo, toggleLogo, triggerLogoAnimation]);

  const handlePlayerReady = useCallback((player) => {
    playerRef.current = player;

    player.ready(function () {
      var promise = player.play();
      if (promise !== undefined) {
        promise
          .then(function () {
            console.log("Initiated autoplay.");
          })
          .catch(function (error) {
            console.log("Autoplay failed :/ Trying again with muted audio.");
            player.muted(true);
            player.play();
          });
      }
    });
  }, []);

  const hideModals = () => {
    setShowEpisodes(false);
    setShowInterviews(false);
    setShowInfo(false);
  };

  let titleFromVideo = video.pageTitle
    ? video.pageTitle + " | Interferenzen"
    : video.title;
  let pageTitle = titleFromVideo ? titleFromVideo : "Interferenzen";
  let pageThumbnail = video.thumbnail
    ? window.location.origin + "/images/" + video.thumbnail
    : window.location.origin + "/images/default_thumb.jpg";

  let descriptionFromVideo = video.description;

  return (
    <HelmetProvider>
      <div
        className={
          "video-container" +
          (showInfo ? " modal-open" : "") +
          (logoIsVisible ? " logo-visible" : "") +
          (isIntro ? " intro" : "") +
          (page ? " page " + page : "")
        }
      >
        <div className={"info-container " + (showInfo ? "open" : "")}>
          <div className="logo-container">
            <Logo
              animate={animateLogo}
              toggleLogo={!showInfo ? toggleLogo : undefined}
              visibility={logoIsVisible}
            />
            <Tagline visibility={logoIsVisible} />
            {isIntro && !page && <ModalIntro />}
            {showInfo && (
              <MenuItems
                toggleEpisodes={toggleShowEpisodes}
                toggleInterviews={toggleShowInterviews}
              />
            )}
          </div>
          {showInfo && <ModalInfo />}
        </div>

        <div className="menu">
          <button className="btn btn-info" onClick={toggleShowInfo}>
            {showInfo ? "Schließen" : "Menü"}
          </button>
        </div>

        {showEpisodes && (
          <ModalVideos
            type="episodes"
            onCloseClick={toggleShowEpisodes}
            hide={hideModals}
          />
        )}
        {showInterviews && (
          <ModalVideos
            type="interviews"
            onCloseClick={toggleShowInterviews}
            hide={hideModals}
          />
        )}

        {!page && (
          <>
            <MetaTags
              title={pageTitle}
              description={descriptionFromVideo}
              image={pageThumbnail}
            />
            <VideoPlayer
              options={videoJsOptions}
              toggleLogo={toggleLogo}
              triggerLogoAnimation={triggerLogoAnimation}
              onReady={handlePlayerReady}
              isIntro={isIntro}
            />
          </>
        )}

        {page && <Pages page={page} animate={triggerLogoAnimation} />}
      </div>
    </HelmetProvider>
  );
}
