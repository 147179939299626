import { useNavigate } from "react-router-dom";

import MetaTags from "../MetaTags";
import ModalVideos from "../Modals/ModalVideos";

export default function PageEpisodes() {
  let pageTitle = "Episoden | Interferenzen";
  let description =
    "In der Krise wittern Antidemokrat:innen ihre Chance: Der Glaube an eine Welt, in der finstere Mächte die Geschicke bestimmen und diese Krisen inszenieren, eint die immer lauter werdenden Rechtsextremen, Verschwörungsideolog:innen und Islamist:innen. Gekonnt nutzen diese Bewegungen digitale Räume für ihre Propaganda, Vernetzung und Finanzierung. Angriffe auf die offene Gesellschaft werden in Chatgruppen, Boards und Kommentarspalten organisiert und verhältnismäßig kleine Proteste und Aktionen medial zu digitalen Massenevents stilisiert. Die Sphären des Analogen und Digitalen sind so eng miteinander verschränkt, dass sie sich getrennt voneinander längst nicht mehr verstehen lassen.";
  let thumbnail = window.location.origin + "/images/default_thumb.png";

  let navigate = useNavigate();

  return (
    <>
      <MetaTags title={pageTitle} description={description} image={thumbnail} />
      <ModalVideos type={"episodes"} onCloseClick={() => navigate("/")} />
    </>
  );
}
