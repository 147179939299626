import { Link } from "react-router-dom";
import "./ModalIntro.css";

export default function ModalIntro() {
  return (
    <div className="modal-intro">
      <Link
        className="btn btn-info"
        title="Starte Episode 4"
        to={"/video/blut-boden-strassenkampf/"}
      >
        Start
      </Link>
    </div>
  );
}
