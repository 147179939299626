import { Link } from "react-router-dom";

import "./MenuItems.css";

function MenuItems({ toggleEpisodes, toggleInterviews }) {
  return (
    <div className="menu-items">
      <Link
        className="btn btn-menu-item"
        title="Zeige alle Episoden"
        to={"/episoden/"}
        onClick={toggleEpisodes}
      >
        Episoden
      </Link>
      <Link
        className="btn btn-menu-item"
        title="Zeige alle Interviews"
        to={"/interviews/"}
        onClick={toggleInterviews}
      >
        Interviews
      </Link>
    </div>
  );
}

export default MenuItems;
