const videos = [
  {
    id: 0,
    type: "intro",
    slug: "intro",
    isIntro: true,
    loop: true,
    title: "Intro",
    pageTitle:
      "Interferenzen – Webdoku zu On- und Offline-Dynamiken antidemokratischer Bewegungen",
    description:
      "In der Krise wittern Antidemokrat:innen ihre Chance: Der Glaube an eine Welt, in der finstere Mächte die Geschicke bestimmen und diese Krisen inszenieren, eint die immer lauter werdenden Rechtsextremen, Verschwörungsideolog:innen und Islamist:innen. Gekonnt nutzen diese Bewegungen digitale Räume für ihre Propaganda, Vernetzung und Finanzierung.",
    sources: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/intro-ep04-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/intro-ep04.webm",
        type: "video/webm",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/intro-ep04.mp4",
        type: "video/mp4",
      },
    ],
    poster: "intro-ep04_poster.jpg",
    thumbnail: "default-ep04_thumb.png",
  },
  {
    id: 1,
    type: "episode",
    title: "Putin, Peace und Plandemie",
    pageTitle: "Putin, Peace und Plandemie | Episode 1",
    slug: "putin-peace-und-plandemie",
    description:
      "Vom Ende der Pandemie-Maßnahmen lassen sich Querdenker nicht aufhalten: Gemeinsam mit Friedensbewegten wittern sie die nächste globale Verschwörung, verteidigen und verharmlosen den russischen Angriffskrieg. Selbst kleine Proteste werden online zu vermeintlichen Massenevents des Widerstands. Mit: Julia Smirnova, Melanie Hermann und Holger Marcks",
    length: 1342,
    release: "24. Oktober 2022",
    sources: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/1-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/1-1920.mp4",
        type: "video/mp4",
      },
    ],
    sourcesMobile: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/1-mobile-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/1-mobile.mp4",
        type: "video/mp4",
      },
    ],
    tracks: [
      {
        src: "/tracks/episodes/ep01_subtitles.de_DE.txt",
        srclang: "de",
        label: "Deutsch",
        kind: "subtitles",
        default: true,
      },
      {
        src: "/tracks/episodes/ep01_labels.de_DE.txt",
        srclang: "de",
        label: "labels",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/episodes/ep01_titles.de_DE.txt",
        srclang: "de",
        label: "titles",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/episodes/ep01_links.de_DE.txt",
        srclang: "de",
        label: "links",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/episodes/ep01_comments.de_DE.txt",
        srclang: "de",
        label: "comments",
        kind: "metadata",
        default: false,
      },
    ],
    poster: "episode-1_poster.jpg",
    thumbnail: "episode-1_thumb.png",
  },
  {
    id: 2,
    type: "episode",
    title: "Stadt, Land, Frust",
    pageTitle: "Stadt, Land, Frust | Episode 2",
    slug: "stadt-land-frust",
    description:
      "Woche für Woche versammeln sich vielen sächsischen Kleinstädten wie Oschatz und Wurzen jeweils ein paar Dutzend Menschen zu montäglichen Spaziergängen. Sie werden angetrieben von der Wut auf das vermeintliche politische Establishment. Auf Telegram und TikTok vernetzen und radikalisieren sie sich. Mit: Johannes Kiess und Maik Fielitz",
    length: 827,
    release: "26. Mai 2023",
    sources: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/2-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/2-1920.mp4",
        type: "video/mp4",
      },
    ],
    sourcesMobile: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/2-mobile-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/2-mobile.mp4",
        type: "video/mp4",
      },
    ],
    tracks: [
      {
        src: "/tracks/episodes/ep02_subtitles.de_DE.txt",
        srclang: "de",
        label: "Deutsch",
        kind: "subtitles",
        default: true,
      },
      {
        src: "/tracks/episodes/ep02_labels.de_DE.txt",
        srclang: "de",
        label: "labels",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/episodes/ep02_titles.de_DE.txt",
        srclang: "de",
        label: "titles",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/episodes/ep02_links.de_DE.txt",
        srclang: "de",
        label: "links",
        kind: "metadata",
        default: false,
      },
      // {
      //   src: "/tracks/episodes/ep02_comments.de_DE.txt",
      //   srclang: "de",
      //   label: "comments",
      //   kind: "metadata",
      //   default: false,
      // },
    ],
    poster: "episode-2_poster.jpg",
    thumbnail: "episode-2_thumb.png",
  },
  {
    id: 3,
    type: "episode",
    title: "Terror, TikTok, Judenhass",
    pageTitle: "Terror, TikTok, Judenhass | Episode 3",
    slug: "terror-tiktok-judenhass",
    description:
      "Oktober 2023: Deutschland erlebt eine neue Welle des Judenhasses. In Berlin werden Davidsterne an Häuserwände gemalt, Mülltonnen brennen und Synagogen werden angegriffen. Tausende demonstrieren gegen Israel, während Falschmeldungen in sozialen Medien das Protestgeschehen anheizen. Wie werden die Ereignisse von antisemitischen Motiven und der Agitation israelfeindlicher Gruppen angetrieben? Wie wirken diese Proteste auf Jüdinnen und Juden in Deutschland? Mit: Harel Chorev, Isolde Vogel und Rosa Jellinek",
    length: 1203,
    release: "4. Dezember 2023",
    sources: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/3-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/3-1920.mp4",
        type: "video/mp4",
      },
    ],
    sourcesMobile: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/3-mobile-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/3-mobile.mp4",
        type: "video/mp4",
      },
    ],
    tracks: [
      {
        src: "/tracks/episodes/ep03_subtitles.de_DE.txt",
        srclang: "de",
        label: "Deutsch",
        kind: "subtitles",
        default: true,
      },
      {
        src: "/tracks/episodes/ep03_labels.de_DE.txt",
        srclang: "de",
        label: "labels",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/episodes/ep03_titles.de_DE.txt",
        srclang: "de",
        label: "titles",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/episodes/ep03_links.de_DE.txt",
        srclang: "de",
        label: "links",
        kind: "metadata",
        default: false,
      },
    ],
    poster: "episode-3_poster.jpg",
    thumbnail: "episode-3_thumb.png",
  },
  {
    id: 4,
    type: "episode",
    title: "Blut, Boden, Straßenkampf",
    pageTitle: "Blut, Boden, Straßenkampf | Episode 4",
    slug: "blut-boden-strassenkampf",
    description:
      "Die blanke Gewalt fasziniert. Über Hunderttausend Menschen verfolgen auf Telegram, Instagram und YouTube regelmäßig die Auseinandersetzungen von Hooligans und Fußballfans. Die Szene ist transnational und digital vernetzt. Nicht nur die Liebe zu einem brutalen Hobby treibt sie an – rechtsextreme Ideologien und ein völkisches Männlichkeitsbild sind weit verbreitet. In offen rechtsextremen Messenger-Kanälen wird rechter Terror verherrlicht und zu Demonstrationen und direkten Angriffen auf politische Gegner aufgerufen. Mit: Robert Claus und Karolin Schwarz",
    length: 713,
    release: "18. Dezember 2023",
    sources: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/4-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/4-1920.mp4",
        type: "video/mp4",
      },
    ],
    sourcesMobile: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/4-mobile-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/episodes/4-mobile.mp4",
        type: "video/mp4",
      },
    ],
    tracks: [
      {
        src: "/tracks/episodes/ep04_subtitles.de_DE.txt",
        srclang: "de",
        label: "Deutsch",
        kind: "subtitles",
        default: true,
      },
      {
        src: "/tracks/episodes/ep04_labels.de_DE.txt",
        srclang: "de",
        label: "labels",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/episodes/ep04_titles.de_DE.txt",
        srclang: "de",
        label: "titles",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/episodes/ep04_links.de_DE.txt",
        srclang: "de",
        label: "links",
        kind: "metadata",
        default: false,
      },
    ],
    poster: "episode-4_poster.jpg",
    thumbnail: "episode-4_thumb.png",
  },
  {
    id: 7,
    slug: "interview-julia-smirnova",
    type: "interview",
    title: "Julia Smirnova",
    pageTitle:
      "Julia Smirnova über Desinformation und Verschwörungsmythen (Interview)",
    description:
      "Julia Smirnova arbeitet als Analystin für das Institute for Strategic Dialogue (ISD) und untersucht die Verbreitung von Desinformationen, Verschwörungsmythen und extremistischen Ideologien im Internet.",
    length: 1660,
    release: "24. Oktober 2022",
    sources: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/smirnova-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/smirnova-1920.mp4",
        type: "video/mp4",
      },
    ],
    sourcesMobile: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/smirnova-mobile-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/smirnova-mobile.mp4",
        type: "video/mp4",
      },
    ],
    tracks: [
      {
        src: "/tracks/interviews/smirnova_subtitles.de_DE.txt",
        srclang: "de",
        label: "Deutsch",
        kind: "subtitles",
        default: true,
      },
      {
        src: "/tracks/interviews/smirnova_labels.de_DE.txt",
        srclang: "de",
        label: "labels",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/smirnova_titles.de_DE.txt",
        srclang: "de",
        label: "titles",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/smirnova_links.de_DE.txt",
        srclang: "de",
        label: "links",
        kind: "metadata",
        default: false,
      },
    ],
    poster: "julia-smirnova_poster.jpg",
    thumbnail: "julia-smirnova_thumb.png",
  },
  {
    id: 8,
    slug: "interview-melanie-hermann",
    type: "interview",
    title: "Melanie Hermann",
    pageTitle:
      "Melanie Hermann über Verschwörungsdenken und Antisemitismus (Interview)",
    description:
      "Melanie Hermann ist Expertin für Antifeminismus, Antisemitismus und Verschwörungsideologie. Sie promoviert an der Leibniz-Universität in Hannover zum Verhältnis von Antisemitismus und Antifeminismus im Rechtsterrorismus.",
    length: 1775,
    release: "24. Oktober 2022",
    sources: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/hermann-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/hermann-1920.mp4",
        type: "video/mp4",
      },
    ],
    sourcesMobile: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/hermann-mobile-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/hermann-mobile.mp4",
        type: "video/mp4",
      },
    ],
    tracks: [
      {
        src: "/tracks/interviews/hermann_subtitles.de_DE.txt",
        srclang: "de",
        label: "Deutsch",
        kind: "subtitles",
        default: true,
      },
      {
        src: "/tracks/interviews/hermann_labels.de_DE.txt",
        srclang: "de",
        label: "labels",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/hermann_titles.de_DE.txt",
        srclang: "de",
        label: "titles",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/hermann_links.de_DE.txt",
        srclang: "de",
        label: "links",
        kind: "metadata",
        default: false,
      },
    ],
    poster: "melanie-hermann_poster.jpg",
    thumbnail: "melanie-hermann_thumb.png",
  },
  {
    id: 9,
    slug: "interview-holger-marcks",
    type: "interview",
    title: "Holger Marcks",
    pageTitle:
      "Holger Marcks über Digitalisierung und die extreme Rechte (Interview)",
    description:
      "Holger Marcks ist Sozialwissenschaftler und Radikalisierungsforscher und beschäftigt sich vor allem mit Fragen zu rechtsextremen, islamistischen Radikalisierungsverläufen im Kontext der Digitalisierung.",
    length: 2096,
    release: "24. Oktober 2022",
    sources: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/marcks-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/marcks-1920.mp4",
        type: "video/mp4",
      },
    ],
    sourcesMobile: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/marcks-mobile-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/marcks-mobile.mp4",
        type: "video/mp4",
      },
    ],
    tracks: [
      {
        src: "/tracks/interviews/marcks_subtitles.de_DE.txt",
        srclang: "de",
        label: "Deutsch",
        kind: "subtitles",
        default: true,
      },
      {
        src: "/tracks/interviews/marcks_labels.de_DE.txt",
        srclang: "de",
        label: "labels",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/marcks_titles.de_DE.txt",
        srclang: "de",
        label: "titles",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/marcks_links.de_DE.txt",
        srclang: "de",
        label: "links",
        kind: "metadata",
        default: false,
      },
    ],
    poster: "holger-marcks_poster.jpg",
    thumbnail: "holger-marcks_thumb.png",
  },
  {
    id: 10,
    slug: "interview-maik-fielitz",
    type: "interview",
    title: "Maik Fielitz",
    pageTitle:
      "Maik Fielitz über Telegram als Mobilisierungsmaschine (Interview)",
    description:
      "Maik Fielitz ist Konfliktforscher und arbeitet zu den Themn Rechtsextremismus und Digitalisierung. Er ist Co-Leiter der Forschungsstelle der Bundesarbeitsgemeinschaft (BAG) »Gegen Hass im Netz«.",
    length: 2793,
    release: "26. Mai 2023",
    sources: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/fielitz-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/fielitz-1920.mp4",
        type: "video/mp4",
      },
    ],
    sourcesMobile: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/fielitz-mobile-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/fielitz-mobile.mp4",
        type: "video/mp4",
      },
    ],
    tracks: [
      {
        src: "/tracks/interviews/fielitz_subtitles.de_DE.txt",
        srclang: "de",
        label: "Deutsch",
        kind: "subtitles",
        default: true,
      },
      {
        src: "/tracks/interviews/fielitz_labels.de_DE.txt",
        srclang: "de",
        label: "labels",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/fielitz_titles.de_DE.txt",
        srclang: "de",
        label: "titles",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/fielitz_links.de_DE.txt",
        srclang: "de",
        label: "links",
        kind: "metadata",
        default: false,
      },
    ],
    poster: "maik-fielitz_poster.jpg",
    thumbnail: "maik-fielitz_thumb.png",
  },
  {
    id: 11,
    slug: "interview-johannes-kiess",
    type: "interview",
    title: "Johannes Kiess",
    pageTitle:
      "Johannes Kiess über rechtsextreme Protestkultur in Sachsen (Interview)",
    description:
      "Johannes Kiess ist Soziologe und forscht zu politischen Einstellungen wie Rechtsextremismus und Antisemitismus. Er ist stellvertretender Direktor des Else-Frenkel-Brunswik-Instituts (EFBI) für Demokratieforschung an der Uni Leipzig.",
    length: 1972,
    release: "26. Mai 2023",
    sources: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/kiess-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/kiess-1920.mp4",
        type: "video/mp4",
      },
    ],
    sourcesMobile: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/kiess-mobile-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/kiess-mobile.mp4",
        type: "video/mp4",
      },
    ],
    tracks: [
      {
        src: "/tracks/interviews/kiess_subtitles.de_DE.txt",
        srclang: "de",
        label: "Deutsch",
        kind: "subtitles",
        default: true,
      },
      {
        src: "/tracks/interviews/kiess_labels.de_DE.txt",
        srclang: "de",
        label: "labels",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/kiess_titles.de_DE.txt",
        srclang: "de",
        label: "titles",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/kiess_links.de_DE.txt",
        srclang: "de",
        label: "links",
        kind: "metadata",
        default: false,
      },
    ],
    poster: "johannes-kiess_poster.jpg",
    thumbnail: "johannes-kiess_thumb.png",
  },
  {
    id: 12,
    slug: "interview-isolde-vogel",
    type: "interview",
    title: "Isolde Vogel",
    pageTitle:
      "Isolde Vogel über antisemitische Narrative und die Rolle von Sozialen Medien (Interview)",
    description:
      "Isolde Vogel ist Zeithistorikerin und forscht zu Antisemitismus, Nationalsozialismus und völkischer Ideologie.",
    length: 1334,
    release: "4. Dezember 2023",
    sources: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/vogel-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/vogel-1920.mp4",
        type: "video/mp4",
      },
    ],
    sourcesMobile: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/vogel-mobile-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/vogel-mobile.mp4",
        type: "video/mp4",
      },
    ],
    tracks: [
      {
        src: "/tracks/interviews/vogel_subtitles.de_DE.txt",
        srclang: "de",
        label: "Deutsch",
        kind: "subtitles",
        default: true,
      },
      {
        src: "/tracks/interviews/vogel_labels.de_DE.txt",
        srclang: "de",
        label: "labels",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/vogel_titles.de_DE.txt",
        srclang: "de",
        label: "titles",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/vogel_links.de_DE.txt",
        srclang: "de",
        label: "links",
        kind: "metadata",
        default: false,
      },
    ],
    poster: "isolde-vogel_poster.jpg",
    thumbnail: "isolde-vogel_thumb.png",
  },
  {
    id: 13,
    slug: "interview-robert-claus",
    type: "interview",
    title: "Robert Claus",
    pageTitle:
      "Robert Claus über die transnationale rechtsextreme Hooligan-Szene (Interview)",
    description:
      "Robert Claus ist Experte für Rechtsextremismus im Sport. Er forscht und publiziert zu den Themen Fankulturen, Männlichkeit und rechte Gewalt in der Fußball- und Kampfsport-Hooligan-Szene.",
    length: 2568,
    release: "15. Dezember 2023",
    sources: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/claus-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/claus-1920.mp4",
        type: "video/mp4",
      },
    ],
    sourcesMobile: [
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/claus-mobile-hls.m3u8",
        type: "application/x-mpegURL",
      },
      {
        src: "https://s3.eu-central-1.amazonaws.com/files.democ.de/interferenzen/videos/interviews/claus-mobile.mp4",
        type: "video/mp4",
      },
    ],
    tracks: [
      {
        src: "/tracks/interviews/claus_subtitles.de_DE.txt",
        srclang: "de",
        label: "Deutsch",
        kind: "subtitles",
        default: true,
      },
      {
        src: "/tracks/interviews/claus_labels.de_DE.txt",
        srclang: "de",
        label: "labels",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/claus_titles.de_DE.txt",
        srclang: "de",
        label: "titles",
        kind: "metadata",
        default: false,
      },
      {
        src: "/tracks/interviews/claus_links.de_DE.txt",
        srclang: "de",
        label: "links",
        kind: "metadata",
        default: false,
      },
    ],
    poster: "robert-claus_poster.jpg",
    thumbnail: "robert-claus_thumb.png",
  },

];

export function getVideo(slug) {
  return videos.find((video) => video.slug === slug);
}

export function getVideos(type) {
  return type ? videos.filter((video) => video.type === type) : videos;
}
