import "./ModalInfo.css";
import democLogo from "../images/democ_Logo.svg";
import evzLogo from "../images/Stiftung_EVZ_Logo.png";

export default function ModalInfo() {
  return (
    <div className="modal-container modal-info">
      <div className="content">
        <h2>Info</h2>
        <p>
          In der Krise wittern Antidemokrat:innen ihre Chance: Der Glaube an
          eine Welt, in der finstere Mächte die Geschicke bestimmen und diese
          Krisen inszenieren, eint die immer lauter werdenden Rechtsextremen,
          Verschwörungsideolog:innen und Islamist:innen. Gekonnt nutzen diese
          Bewegungen digitale Räume für ihre Propaganda, Vernetzung und
          Finanzierung. Angriffe auf die offene Gesellschaft werden in
          Chatgruppen, Boards und Kommentarspalten organisiert und
          verhältnismäßig kleine Proteste und Aktionen medial zu digitalen
          Massenevents stilisiert. Die Sphären des Analogen und Digitalen sind
          so eng miteinander verschränkt, dass sie sich getrennt voneinander
          längst nicht mehr verstehen lassen.
        </p>
        <p>
          Die Webdoku <i>Interferenzen</i>, die{" "}
          <a target="_blank" rel="noreferrer" href="https://democ.de/">
            democ
          </a>{" "}
          in den Jahren 2022 und 2023 mit Förderung der{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.stiftung-evz.de/"
          >
            Stiftung Erinnerung, Verantwortung und Zukunft (EVZ)
          </a>{" "}
          entwickelte, nimmt diese Verschränkungen in den Blick – wie
          beeinflusst die Digitalität antidemokratische Bewegungen?
        </p>

        <p>
          <a
            href="https://democ.de/interferenzen-webdoku/abschlussbericht/"
            target="_blank"
            rel="noreferrer"
          >
            Abschlussbericht
          </a>
        </p>

        <h3>Förderung</h3>
        <p>
          Gefördert wird das Projekt von der{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.stiftung-evz.de/"
          >
            Stiftung Erinnerung, Verantwortung und Zukunft (EVZ)
          </a>
          .<br />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.stiftung-evz.de/"
          >
            <img
              src={evzLogo}
              alt="EVZ Logo"
              width="400"
              height="94"
              className="evz-logo"
            />
          </a>
        </p>

        <p style={{ marginTop: "4rem" }}>
          <i>
            Interferenzen – Webdoku zu On- und Offline-Dynamiken
            antidemokratischer Bewegungen
          </i>{" "}
          ist ein Projekt von{" "}
          <a target="_blank" rel="noreferrer" href="https://democ.de/">
            democ.
          </a>
          <br />
          <a target="_blank" rel="noreferrer" href="https://democ.de/">
            <img
              src={democLogo}
              alt="democ. Logo"
              width="250"
              height="62"
              className="democ-logo"
            />
          </a>
        </p>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/democev/"
            target="_blank"
            rel="noreferrer"
            title="Facebook"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"></path>
            </svg>
          </a>

          <a
            href="https://www.instagram.com/democ.de/"
            target="_blank"
            rel="noreferrer"
            title="Instagram"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"></path>
            </svg>
          </a>

          <a
            href="https://twitter.com/democ_de"
            target="_blank"
            rel="noreferrer"
            title="Twitter"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"></path>
            </svg>
          </a>

          <a
            href="https://www.youtube.com/democde"
            target="_blank"
            rel="noreferrer"
            title="YouTube"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z"></path>
            </svg>
          </a>

          <a
            href="https://www.tiktok.com/@democ.de"
            target="_blank"
            rel="noreferrer"
            title="TikTok"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
              <path d="M24.602 2.398H5.398c-1.652 0-3 1.348-3 3v19.204c0 1.652 1.348 3 3 3h19.204c1.652 0 3-1.348 3-3V5.398c0-1.652-1.348-3-3-3zm-2.399 10.997a4.496 4.496 0 0 1-4.176-2.012v6.922a5.116 5.116 0 1 1-10.23 0 5.116 5.116 0 0 1 5.113-5.117c.11 0 .215.011.317.015v2.524a2.068 2.068 0 0 0-.317-.032 2.609 2.609 0 1 0 0 5.22c1.445 0 2.719-1.134 2.719-2.58l.023-11.753h2.41a4.494 4.494 0 0 0 4.141 4.012zm0 0" />
            </svg>
          </a>
        </div>

        <h3>Team</h3>
        <p>
          Projektleitung: Grischa Stanjek
          <br />
          Projektmitarbeit: Linus Kebba Pook
        </p>
        <br />
        <h4>Episode 1</h4>
        <p>
          Autoren: Grischa Stanjek, Linus Kebba Pook, Stephan Cleef
          <br />
          Kamera: Stephan Cleef, Jonas Fedders, Linus Kebba Pook, Grischa
          Stanjek, Tuija Wigard
          <br />
          Schnitt, Sprecher &amp; Ton: Stephan Cleef
          <br />
          <p>
            Wir danken dem{" "}
            <a href="https://bum.berlin/" target="_blank" rel="noreferrer">
              bUm
            </a>{" "}
            als Drehort.
          </p>
        </p>
        <h4>Episode 2</h4>
        <p>
          Autoren: Grischa Stanjek, Linus Kebba Pook
          <br />
          Kamera: Stephan Cleef, Linus Kebba Pook, Grischa Stanjek, Jonas
          Fedders
          <br />
          Schnitt: Stephan Cleef, Linus Kebba Pook
          <br />
          Sprecher: Stephan Cleef
          <br />
        </p>
        <h4>Episode 3</h4>
        <p>
          Autoren: Linus Kebba Pook, Grischa Stanjek
          <br />
          Kamera: Stephan Cleef, Linus Kebba Pook, Grischa Stanjek, Jonas
          Fedders, Samuel Winter
          <br />
          Schnitt: Stephan Cleef, Linus Kebba Pook
          <br />
          Redaktionelle Assistenz: Ada Baumkötter
          <br />
          Sprecher: Stephan Cleef
          <br />
        </p>
        <h4>Episode 4</h4>
        <p>
          Autoren: Linus Kebba Pook, Grischa Stanjek
          <br />
          Kamera: Stephan Cleef, Linus Kebba Pook, Grischa Stanjek, Samuel
          Winter
          <br />
          Schnitt: Stephan Cleef, Linus Kebba Pook
          <br />
          Redaktionelle Assistenz: Ada Baumkötter
          <br />
          Sprecher: Stephan Cleef
          <br />
        </p>
        <p>
          <br />
          Gestaltung:{" "}
          <a href="https://gegenfeuer.net/" target="_blank" rel="noreferrer">
            gegenfeuer.net
          </a>
          <br />
          Programmierung: Grischa Stanjek
        </p>

        <h3>Kontakt</h3>
        <p>
          <a href="mailto:kontakt@democ.de" target="_blank" rel="noreferrer">
            kontakt@democ.de
          </a>
          <br />
          Telefon:{" "}
          <a href="tel:+493057712221" target="_blank" rel="noreferrer">
            030 57712221
          </a>
        </p>

        <h3>Version</h3>
        <p>Letzte Aktualisierung 05.02.2024</p>

        <p style={{ marginTop: "4rem" }}>
          <a href="/impressum/">Impressum &amp; Datenschutzerklärung</a>
        </p>

        <p style={{ marginTop: "2rem" }}>
          <i>&copy; democ e. V.</i>
        </p>
      </div>
    </div>
  );
}
