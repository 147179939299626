// reference: https://github.com/videojs/video.js/blob/master/docs/guides/react.md
import React, { useEffect, useRef, useState, useCallback } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css"; // ToDo: remove video-js.css
import "./VideoPlayer.css";
import VideoLabel from "../VideoLabel/VideoLabel";
import VideoLink from "../VideoLink/VideoLink";
import MessageQueue, { useMessageQueue } from "../MessageQueue/MessageQueue";

export const VideoPlayer = ({
  options,
  isIntro,
  onReady,
  toggleLogo,
  triggerLogoAnimation,
}) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  // const [remainingVideoPlay, setRemainingVideoPlay] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [skipTo, setSkipTo] = useState(0);
  const [showSubtitles, setShowSubtitles] = useState(true);
  const [offsetToolboxClass, setOffsetToolboxClass] = useState("");
  const [offsetToolbox, setOffsetToolbox] = useState(0);

  const [videoOverlayLabelText, setVideoOverlayLabelText] = useState(false);
  const [videoOverlayLabelVisible, setVideoOverlayLabelVisible] =
    useState(false);
  const [videoOverlayTitleText, setVideoOverlayTitleText] = useState(false);
  const [videoOverlayTitleVisible, setVideoOverlayTitleVisible] =
    useState(false);
  const [videoOverlayLinkText, setVideoOverlayLinkText] = useState(false);
  const [videoOverlayLinkVisible, setVideoOverlayLinkVisible] = useState(false);

  const { addMessage, clearMessages, messages } = useMessageQueue();

  const resetVideoOverlay = useCallback(() => {
    clearMessages();
    setVideoOverlayLabelVisible(false);
    setVideoOverlayLabelText(false);
    setVideoOverlayTitleVisible(false);
    setVideoOverlayTitleText(false);
    setVideoOverlayLinkVisible(false);
    setVideoOverlayLinkText(false);
  }, [clearMessages]);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const isPortrait = useCallback(() => {
    let { width, height } = getWindowDimensions();
    return width < height ? true : false;
  }, []);

  useEffect(() => {
    console.log("useEffect triggered");

    // replace sources with sources for mobile if window is in portrait orientation
    if (isPortrait() && options.sourcesMobile) {
      options.sources = options.sourcesMobile;
    }

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      videojs.log("First initialization");

      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        // caption settings
        player.textTrackSettings.setValues({
          fontPercent: 0.5,
        });
        player.textTrackSettings.updateDisplay();

        onReady && onReady(player);
        videojs.log("Player is ready");

        player.on("loadeddata", () => {
          console.log("loadeddata");
          triggerLogoAnimation();

          if (!isIntro) {
            // hide logo after 5s
            setTimeout(() => {
              toggleLogo("hide");
            }, 5000);
          }
        });

        player.on("play", () => {
          videojs.log("Playing", player.src());
          setIsPlaying(true);
          setIsMuted(player.muted());
        });

        player.on("loadedmetadata", (event) => {
          let tracks = player.textTracks();
          setTotalDuration(player.duration());

          let metaTrackLabels; // video labels (e. g. date & location)
          let metaTrackTitles; // video titles (e. g. main title, chapters)
          let metaTrackLinks; // links (e. g. to other videos)
          let metaTrackComments; // comments (e. g. emulating live video)

          for (let i = 0; i < tracks.length; i++) {
            let track = tracks[i];

            if (track.kind === "metadata") {
              track.mode = "hidden";
              switch (track.label) {
                case "labels":
                  metaTrackLabels = track;
                  break;
                case "titles":
                  metaTrackTitles = track;
                  break;
                case "links":
                  metaTrackLinks = track;
                  break;
                case "comments":
                  metaTrackComments = track;
                  break;
                default:
                  break;
              }
            }
          }

          if (metaTrackLabels) {
            metaTrackLabels.addEventListener("cuechange", () => {
              updateVideoOverlay(metaTrackLabels, "label");
            });
          }

          if (metaTrackTitles) {
            metaTrackTitles.addEventListener("cuechange", () => {
              updateVideoOverlay(metaTrackTitles, "title");
            });
          }

          if (metaTrackLinks) {
            metaTrackLinks.addEventListener("cuechange", () => {
              updateVideoOverlay(metaTrackLinks, "link");
            });
          }

          if (metaTrackComments) {
            metaTrackComments.addEventListener("cuechange", () => {
              let activeCue = metaTrackComments.activeCues[0];
              let text = activeCue ? activeCue.text : false;
              if (text) addMessage(text, 10000);
            });
          }
          videojs.log("Text tracks added", tracks.length);
        });

        player.on("pause", (event) => {
          videojs.log("Pause");
          setIsPlaying(false);
        });

        player.on("ended", (event) => {
          videojs.log("Ended");
        });

        player.on("timeupdate", (event) => {
          setPlayedSeconds(player.currentTime());
          // setRemainingVideoPlay(player.remainingTime());
        });

        if (videoElement) {
          videoElement.setAttribute("webkit-playsinline", true);
          videoElement.setAttribute("playsinline", true);
        }
      }));
    } else {
      videojs.log("Updating existing player");
      const player = playerRef.current;

      resetVideoOverlay();

      player.poster(options.poster);
      player.autoplay("any");
      player.muted(false);
      player.src(options.sources);

      var oldTracks = player.remoteTextTracks();
      var i = oldTracks.length;
      while (i--) {
        player.removeRemoteTextTrack(oldTracks[i]);
      }

      if (options?.tracks) {
        options.tracks.forEach(function (track) {
          player.addRemoteTextTrack(track, false);
        });
      }
    }
  }, [
    addMessage,
    resetVideoOverlay,
    isPortrait,
    isIntro,
    onReady,
    options,
    toggleLogo,
    triggerLogoAnimation,
  ]);

  const play = () => {
    if (playerRef) {
      playerRef.current.play();
    }
  };

  const pause = () => {
    if (playerRef) {
      playerRef.current.pause();
    }
  };

  const togglePlay = () => {
    if (isPlaying) {
      pause();
      toggleLogo("show");
    } else {
      play();
      toggleLogo("hide");
    }
  };

  const jumpTo = () => {
    if (playerRef) {
      playerRef.current.currentTime(skipTo);
      videojs.log("Skip to", skipTo);
    }
  };

  const secondsToHms = (secs) => {
    let hours = Math.floor(secs / 3600);
    let minutes = Math.floor(secs / 60) % 60;
    let seconds = Math.floor(secs % 60);
    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  };

  const updateSeekTooltip = (event) => {
    let x, px;
    if (
      event.type === "touchmove" ||
      event.type === "touchend" ||
      event.type === "touchstart"
    ) {
      var touch = event.touches[0] || event.changedTouches[0];
      x = touch.clientX;
      px = touch.pageX;
    } else {
      x = event.clientX;
      px = event.pageX;
    }

    const skipToSeconds = Math.round(
      (x / event.target.clientWidth) *
        parseInt(event.target.getAttribute("max"), 10)
    );
    let offset = 40;

    if (px >= 40 && px <= event.target.clientWidth - 50) {
      offset = px;
    } else {
      if (px >= 40) {
        offset = event.target.clientWidth - 50;
      }
    }
    setSkipTo(skipToSeconds > 0 ? skipToSeconds : 0);
    setOffsetToolbox(offset);
    setOffsetToolboxClass("");
  };

  const hideSeekTooltip = (event) => {
    setOffsetToolboxClass("hide");
  };

  const toggleSubtitles = () => {
    var mainTracks = playerRef.current.textTracks();
    for (var i = 0; i < mainTracks.length; i++) {
      var track = mainTracks[i];
      if (track.kind === "subtitles") {
        track.mode = !showSubtitles ? "showing" : "disabled";
      }
    }
    setShowSubtitles((obj) => !obj);
  };

  const toggleMuted = () => {
    if (isMuted) {
      playerRef.current.muted(false);
      setIsMuted(false);
    } else {
      playerRef.current.muted(true);
      setIsMuted(true);
    }
  };

  const updateVideoOverlay = (cues, type) => {
    let activeCue = cues.activeCues[0];
    let text = activeCue ? activeCue.text : false;
    let visibility = text ? true : false;
    let timeout = text ? 0 : 200;

    switch (type) {
      case "label":
        setVideoOverlayLabelVisible(visibility);
        setTimeout(() => {
          setVideoOverlayLabelText(text);
        }, timeout);
        break;
      case "title":
        setVideoOverlayTitleVisible(visibility);
        setTimeout(() => {
          setVideoOverlayTitleText(text);
        }, timeout);
        break;
      case "link":
        setVideoOverlayLinkVisible(visibility);
        setTimeout(() => {
          setVideoOverlayLinkText(text);
        }, timeout);
        break;
      default:
        break;
    }
  };

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div className="custom-controls">
        <button className="btn-play-toggle" onClick={togglePlay}>
          {isPlaying ? "Pause" : "Abspielen"}
        </button>

        {options.tracks && (
          <button className="btn-subtitles" onClick={toggleSubtitles}>
            {showSubtitles ? (
              "Untertitel"
            ) : (
              <span className="line-through">Untertitel</span>
            )}
          </button>
        )}

        {!isIntro && (
          <button className="audio-control" onClick={toggleMuted}>
            {isMuted ? <span className="line-through">Audio</span> : "Audio"}
          </button>
        )}
      </div>

      <VideoLink
        text={videoOverlayLinkText}
        visibility={videoOverlayLinkVisible}
        pause={pause}
      />
      <VideoLabel
        text={videoOverlayLabelText}
        visibility={videoOverlayLabelVisible}
        type="label"
      />
      <VideoLabel
        text={videoOverlayTitleText}
        visibility={videoOverlayTitleVisible}
        type="title"
      />
      <MessageQueue messages={messages} />

      <div className="video-progress">
        <progress
          id="progress-bar"
          value={Math.floor(playedSeconds)}
          min="0"
          max={Math.floor(totalDuration)}
        ></progress>
        <input
          className="seek"
          id="seek"
          value={Math.floor(playedSeconds)}
          min="0"
          type="range"
          step="1"
          max={Math.floor(totalDuration)}
          onTouchStart={updateSeekTooltip}
          onTouchMove={updateSeekTooltip}
          onTouchEnd={hideSeekTooltip}
          onMouseMove={updateSeekTooltip}
          onInput={jumpTo}
        />
        <div
          className={"seek-tooltip " + offsetToolboxClass}
          id="seek-tooltip"
          style={{ left: offsetToolbox + "px" }}
        >
          {secondsToHms(skipTo)}
        </div>
      </div>
      <video
        disablePictureInPicture
        ref={videoRef}
        className="video-js vjs-big-play-centered"
        onClick={togglePlay}
        onTouchStart={togglePlay}
      />
    </div>
  );
};

export default VideoPlayer;
