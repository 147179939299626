import "./VideoLabel.css";

function VideoLabel({ text, visibility, type }) {
  let parsedText = text ? text.split(/[\r\n]+/) : false;
  let i = 1;
  let lines = text
    ? parsedText.map((line) => (
        <div key={i++} className={"line-" + i}>
          {/*  <span className="glitch animate" data-glitch={line}> */}
          <span>{line}</span>
        </div>
      ))
    : false;
  return (
    <div
      className={
        "video-overlay video-overlay-" +
        type +
        " lines-" +
        (i - 1) +
        (visibility ? " show" : " hidden")
      }
    >
      {lines}
    </div>
  );
}

export default VideoLabel;
